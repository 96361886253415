
  import * as userEditorScriptEntry from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-client/src/editor.app.ts';
  const editorScriptEntry = userEditorScriptEntry;

  
var initI18n = null;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    

  
import { createHttpClient } from '@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp';
    


  
  import { editorReadyWrapper, onEventWrapper, createFlowAPIFabric, getAppManifestWrapper } from '@wix/yoshi-flow-editor/runtime/esm/editorScript.js';

  let editorReady = editorScriptEntry.editorReady;
  const onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });
  const sentryConfig = {
    DSN: 'https://baf30a2b91654c5a840931f0137bed30@sentry.wixpress.com/1696',
    id: 'baf30a2b91654c5a840931f0137bed30',
    projectName: 'communities-forum-client',
    teamName: 'forum',
    
  };
  const experimentsConfig = {"centralized":true,"scopes":["wix-forum"]};
  const translationsConfig = {"enabled":false,"icuEnabled":false};
  const defaultTranslations = null;
  const createFlowAPI = createFlowAPIFabric({
    sentryConfig,
    experimentsConfig,
    translationsConfig,
    defaultTranslations,
    shouldUseEssentials: true,
    artifactId: 'communities-forum-client',
    appDefId: '14724f35-6794-cd1a-0244-25fd138f9242',
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: 'assets/locales',
  });

  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady,
      createFlowAPI
    });
    if (false) {
      const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
      editorReady = hot(module, editorReady)
    }
  }
  export { editorReady };
  export { onEvent };
  export const exports = editorScriptEntry.exports;
  export const getAppManifest = editorScriptEntry.getAppManifest ? getAppManifestWrapper(editorScriptEntry.getAppManifest, createFlowAPI) : undefined;
  export const handleAction = editorScriptEntry.handleAction;
  
